import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnBtnCard from '../atoms/columnBtnCard'
import ColumnH1 from '../atoms/columnH1'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    span {
      display: none;
    }
  }

  ${BreakPoints.large} {
    margin-bottom: 10px;
  }
`

/**
 * 関連記事カードセット
 * TODO 川上申し送り事項：記事詳細下部に表示されるコンポーネントです。
 * ここでは、表示している記事に関連した他の記事3件が表示される予定です
 * @constructor
 */
const ColumnRelationCards0018 = () => {
  const routeParam = useRouteParam('/column/details/C0019/')
  return (
    <Wrapper>
      <ColumnH1 label="関連コラム" />
      <section>
        <ColumnBtnCard
          href={routeParam}
          imgId="c0019.jpg"
          label="自由度の高い新しい働き方「スーパーフレックス制度」とは？"
          date="2022.04.15"
        />
      </section>
    </Wrapper>
  )
}

export default ColumnRelationCards0018
