import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: 0;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
      &.img-graph {
        padding-bottom: 10px;
      }
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0018 = () => (
  <Wrapper>
    <ColumnH1 label="フレックスタイム制はどんな制度？導入のメリットとポイント" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        働き方改革の施行により多様な働き方が推進されています。子の養育や親の介護と並行して職業生活を営むことに対する社会的な要請は今後益々増えてくるものと推察します。
        <br />
        <br />
        労働基準法は昭和22年4月7日に交付された法律であり、当時の工場労働者をモデルとして制定されています。時代の変化により働き方の多様化は否めず、全従業員が横並びで同じ労働時間管理されるという労務管理では働きたくても働けないという事情を抱えた有能な労働者の雇用が難しくなりました。
        <br />
        そこで、労働基準法の中に
        <span>始業および終業時刻を労働者の決定に委ねる</span>
        フレックスタイム制が設けられました。
        <br />
        <br />
        今回は、フレックスタイム制にフォーカスをあて、解説してまいります。
      </p>
      <img src="/images/column/details/c0018.jpg" alt="キンクラコラム画像18" />
      <h1>フレックスタイム制とは</h1>
      <p>
        一定期間の総労働時間を定めておき、労働者がその範囲内で始業および終業時刻を選択できる制度です。労働契約の中で賃金と並び重要な条件である労働時間に一定の裁量が認められることから職業生活と家庭生活の調和を図ることに主眼を置いています。よくある誤りで、「始業または終業」を労働者の決定に委ねるとする定めは、フレックスタイム制の要件を満たしていません。必ず両方の時刻を労働者の決定に委ねる必要があります。
        <br />
        <br />
        使用者側としては、多くの人手が必要とされる時間帯にフレックスタイム制を導入することで人手不足に陥るのではないかとの懸念事項も予想されます。それについては「コアタイム」といい、労働者が必ず働かなければならない時間帯を定めておくことで、懸念事項は可能な限り払拭できると考えます。また、フレックスタイム制は深夜の割増賃金の規制から脱却しているわけではありませんので、注意が必要です。よって、「フレキシブルタイム」といい、労働者が選択可能な時間帯を設けておくことで、深夜に状態的に働くという選択肢を除外することができます。尚、コアタイム、フレキシブルタイムを設けるか否かは任意です。
        <br />
        <br />
        尚、派遣労働者に対してフレックスタイム制を採用する場合は派遣元が、就業規則への明記、労使協定の締結、労働者派遣契約での定めを行う必要があります。
      </p>
      <Introduction003 />
      <h1>フレックスタイムの使用者側のメリット</h1>
      <p>
        フレックスタイム制は2019年4月1日に改正され、旧来1か月が上限であったものの
        <span>上限が3か月に改正</span>
        されました。まずは1か月フレックスタイム制のメリットから確認しましょう。
        <br />
        <br />
        1か月フレックスタイム制は、
        <span>
          フレックスタイム制導入に際して所轄労働基準監督署への届出が不要（1か月超の場合は届出が必要）
        </span>
        です。そして、1か月間（以下、清算期間）における法定労働時間の総枠を超えた時間が時間外労働とされます（通常の固定労働時間制の場合は1日および1週間で法定労働時間を超えた部分が時間外労働とされる）。
        <br />
        <br />
        清算期間を4週間で設定する場合は（法定労働時間が40時間の場合）、160.0時間。30日（例えば4月）で設定する場合、171.4時間。31日で設定する場合（例えば5月）では177.1時間が総枠となります。この時間を超えた場合に割増賃金が発生するという理解です。
        <br />
        <br />
        1か月超のフレックスタイム制の場合は
        <span>精算期間における法定労働時間の総枠を超えないこと</span>
        が1つ目の要件です。1か月超の清算期間は61日（例えば4月と5月）の場合、348.5時間。91日（4月～6月）520.0時間となります。詳細は下表をご覧ください。
        <br />
        <br />
        そして、1か月超のフレックスタイム制の2つ目の要件として各期間を平均して1週間あたりの労働時間が50時間を超えないこと※が必要です。
        <br />
        <br />
        ※清算期間を1週間ごとに区分した各期間における週平均50時間の枠の計算式
        <br />
        50時間×清算期間を1か月ごとに区分した各期間の歴日数÷7
        <br />
        詳細は下表をご覧ください。
      </p>
      <h3>清算期間における法定労働時間の総枠</h3>
      <img src="/images/column/details/c0018_g01.jpg" alt="キンクラコラム" />
      <p>
        改正後の大きなメリットとして（別途添付図）3か月のフレックスタイム制とした場合に改正前は1か月目に法定労働時間を超えた場合に、割増賃金を支払う必要がありました。改正後は例えば3か月目に法定労働時間よりも短い時間での労働時間であった場合、欠勤にもならず、かつ、割増賃金の支払い義務がなくなるということです。
      </p>
      <h1>フレックスタイム制と有給休暇の関係</h1>
      <p>
        フレックスタイム制を採用している労働者が有給休暇を取得した場合、有給休暇と賃金の関係は標準となる1日の労働時間を労働したものとして取り扱われます。
      </p>
      <h1 className="read">最後に</h1>
      <p>
        フレックスタイム制は企業にとっても労働者にとっても幅広く選択が可能となりました。注意点は
        <span>
          1か月超を採用する場合、届け出義務や労働時間の管理が煩雑となる
        </span>
        点が挙げられます。労働時間管理を手作業行うとなると膨大な時間を要することからアウトソーシングや専門家の意見を参考にしながら進めていくことで思わぬ形での法違反の回避と、適正かつ有効的な制度の活用ができると考えます。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0018
